import React from 'react';
import Waves from '../components/waves';
import Navigation from '../components/navigation';

import Footer from '../components/footer';

import '../css/typography.css';

const Booking = () => {
    return (
        <body className="leading-normal tracking-normal text-white gradient">
        <Navigation showButton={true} />
        <div className="pt-4">
            <Waves />
        </div>

        <section className="bg-white">
            <iframe
                className="container max-w-5xl h-screen mx-auto"
                src="https://squareup.com/gift/1GA93G7GKSEJP/order"
                title="gift card" />
        </section>

        <Footer />
        </body>
    )
}

export default Booking;
